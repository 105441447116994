import React from 'react'
import { Head, Link } from '~/components'
import { PageProps } from 'gatsby'
import * as st from '~/assets/styl/PageNotFoundAndSucess.module.styl'
import logo from '~/assets/svg/logo-green.svg'

const Success = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <div className="container">
          <img
            src={logo}
            alt="Logo do empreendimento Marina Sunshine - Open city Campeche"
          />
          <section>
            <h1 className="title">Mensagem enviada com sucesso.</h1>
            <p>
              Sua mensagem foi enviada para nossa central de vendas, em breve
              você irá receber novidades do Marina Sunshine. Obrigado por entrar
              em contato.
            </p>
            <Link href="/" className="btn btn-orange custom-padding">
              Voltar
            </Link>
          </section>
        </div>
      </main>
    </>
  )
}

export default Success
